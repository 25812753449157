import React from 'react';
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '../utils/cn';
import BackgroundComposer from './background/BackgroundComposer';

const plans = [
  {
    name: 'Starter Package Plan',
    price: '294.99',
    description: 'Perfect for small businesses starting their online journey, offering essential support and maintenance to keep your website performing at its best.',
    features: [
      'Reliable Hosting with 99.9% uptime',
      'Unlimited Content Updates',
      'Monthly Performance Reports',
      'SEO Optimization',
      'Security Monitoring',
      'Google Business Profile Management',
    ],
    contractTerms: {
      commitment: '6-month minimum commitment',
      continuation: 'Month-to-month after the initial period',
    },
  },
  {
    name: 'Growth Package Plan',
    price: '594.99',
    description: 'Ideal for growing businesses that need a high-performing website with comprehensive management and advanced features.',
    features: [
      'Premium Hosting with CDN',
      'Priority Content Updates',
      'Weekly Performance Reports',
      'Advanced SEO Optimization',
      'Enhanced Security Suite',
      'Social Media Integration',
      'Marketing Campaign Support',
    ],
    contractTerms: {
      commitment: '6-month minimum commitment',
      continuation: 'Month-to-month after the initial period',
    },
    popular: true,
  },
];

const SubscriptionPlans = () => {
  return (
    <section id="subscriptions" className="py-32 bg-dark relative overflow-hidden">
      <BackgroundComposer intensity="low" colorMode="default">
        <div className="container mx-auto px-4 relative z-10">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Subscription Plans
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Our flexible monthly payment options are designed to help businesses maintain 
              and grow their online presence with ongoing support and updates.
            </p>
          </motion.div>

          <div className="grid lg:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {plans.map((plan, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                whileHover={{ y: -8, scale: 1.02 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className={cn(
                  "group relative p-8 rounded-3xl overflow-visible",
                  "bg-gradient-to-br from-white/[0.05] to-transparent backdrop-blur-sm",
                  "transition-all duration-500",
                  "shadow-lg shadow-primary-900/10",
                  "hover:shadow-xl hover:shadow-primary-900/20",
                  "min-h-[750px] flex flex-col",
                  plan.popular ? "border-2 border-primary-500" : "border border-primary-500/20"
                )}
              >
                {plan.popular && (
                  <motion.div 
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="absolute -top-6 inset-x-0 flex justify-center"
                  >
                    <span className="bg-primary-500 text-white px-6 py-2 rounded-full text-sm font-medium shadow-lg">
                      Most Popular
                    </span>
                  </motion.div>
                )}

                <div className="flex flex-col justify-between h-full space-y-8">
                  <div>
                    <h3 className="text-2xl font-bold text-white mb-2">{plan.name}</h3>
                    <div className="mb-4">
                      <span className="text-4xl font-bold text-primary-400">${plan.price}</span>
                      <span className="text-gray-300 ml-2">per month</span>
                    </div>
                    <p className="text-gray-300">{plan.description}</p>
                  </div>

                  <div className="flex-1 space-y-6">
                    <div>
                      <h4 className="font-semibold text-white mb-4">What's Included:</h4>
                      <ul className="space-y-3">
                        {plan.features.map((feature, featureIndex) => (
                          <motion.li 
                            key={featureIndex} 
                            className="flex items-start"
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ delay: featureIndex * 0.1 }}
                          >
                            <Check className="h-5 w-5 text-primary-400 mr-2 flex-shrink-0 mt-0.5" />
                            <span className="text-gray-300">{feature}</span>
                          </motion.li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <h4 className="font-semibold text-white mb-4">Contract Terms:</h4>
                      <ul className="space-y-2 text-gray-300">
                        <li>{plan.contractTerms.commitment}</li>
                        <li>{plan.contractTerms.continuation}</li>
                      </ul>
                    </div>
                  </div>

                  <motion.button
                    onClick={() => window.open('https://calendly.com/thirtyfivestudio', '_blank')}
                    className={cn(
                      "w-full py-4 rounded-full text-center mt-auto",
                      "transform transition-all duration-300",
                      "hover:scale-105 active:scale-95",
                      plan.popular
                        ? "bg-primary-500 text-white hover:bg-primary-600"
                        : "bg-white/[0.03] text-white border border-primary-500/20 hover:border-primary-500/40"
                    )}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                   {plan.popular ? 'Book a Call' : 'Get Started'}
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </BackgroundComposer>
    </section>
  );
};

export default SubscriptionPlans;
