import React from 'react';
import { cn } from '../../../utils/cn';
import { LogoSVG } from './LogoSVG';

interface LogoProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

const Logo: React.FC<LogoProps> = ({ 
  className,
  width = 892,
  height = 530
}) => {
  return (
    <div className={cn("inline-block", className)}>
      <LogoSVG width={width} height={height} />
    </div>
  );
};

export default Logo;
