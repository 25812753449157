import React from 'react';

interface LogoSVGProps {
  width?: number;
  height?: number;
}

export const LogoSVG: React.FC<LogoSVGProps> = ({ width = 446, height = 265 }) => {
  return (
    <img 
      src="https://i.postimg.cc/RZWk4q3m/Group-1000002222.png"
      alt="ThirtyFive Studio Logo"
      width={width}
      height={height}
      style={{ objectFit: 'contain' }}
    />
  );
};
